import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const sendCoupon = async (values) => {
   const data = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/coupon/send`,
      values
   );
   return data;
};

export function useSendCoupon() {
   const { isLoading, mutate } = useMutation(sendCoupon);

   return { isLoading: isLoading, sendCoupon: mutate };
}
