import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';

const fetchAvailableEmployees = async ({start_date,start_time,service_id,store_id}) => {
   
    start_date = start_date || moment.utc().tz('America/New_York').format('YYYY-MM-DD');
    start_time = start_time || '10:00';
    service_id = service_id || 1;

    //get date part of start_date, split by T
   start_date = start_date.split('T')[0];
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/schedule/employees/service?start_date=${start_date}&start_time=${start_time}&service_id=${service_id}&store_id=${store_id}`
   );
   return data;
};

export function useAvailableEmployees({start_date,start_time,service_id,store_id}) {
   return useQuery(['available_employees', start_date,start_time,service_id,store_id], () => fetchAvailableEmployees({start_date,start_time,service_id,store_id}));
}
