import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchServices = async (store_id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/schedule/services?store_id=${store_id}`
   );
   return data;
};

export function useServices(store_id) {
   return useQuery(['services', store_id], () => fetchServices(store_id));
}
