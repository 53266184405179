import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const makeReservation = async (values) => {
   const data = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/schedule/reservation`,
      values
   );
   return data;
};

export function useMakeReservation() {
   const { isLoading, mutate } = useMutation(makeReservation);

   return { isLoading: isLoading, makeReservation: mutate };
}
