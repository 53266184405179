import { Button, Grid, IconButton, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Otp from './Otp';
import axios from 'axios';
import { Box } from '@mui/system';
import { useStateContext } from '../Contexts/ContextProvider';
import { useGetCustomerInfo } from '../hooks/useGetCustomerInfo';
import { SentimentSatisfiedRounded } from '@mui/icons-material';

const Verify = ({ nextStep }) => {
   const { phone,email,firstName,lastName,setPhone,setFirstName,setLastName,setEmail } = useStateContext();
   const [code, setCode] = useState('+1');
   const [pno, setPno] = useState(phone);
   const [otpShow, setOtpShow] = useState(false);
   const [otp, setOtp] = useState('');

   const { isLoading, data:customer } = useGetCustomerInfo(
      process.env.REACT_APP_STORE_ID,
      pno,
   );

   function isNumeric(n) {
      return !isNaN(parseInt(n)) && isFinite(n);
   }

   const getCode = async () => {
      const e = pno;
      await axios
         .get(process.env.REACT_APP_API_SERVER + '/api/verify/get-code', {
            params: {
               phone_number: e,
               channel: 'sms',
            },
         })
         .then((res) => res.data)
         .then((data) => console.log(data))
         .catch((err) => console.log(err));
   };
   const verifyCode = async () => {
      const e = pno;
      await axios
         .get(process.env.REACT_APP_API_SERVER + '/api/verify/verify-code', {
            params: {
               phone_number: e,
               code: otp,
            },
         })
         .then((res) => res.data)
         .then((data) => {
            localStorage.setItem('phone', pno);

            if (customer!=null && customer.length>0) {
               localStorage.setItem('firstName', customer[0].first_name);
               setFirstName(customer[0].first_name);
               localStorage.setItem('lastName', customer[0].last_name);
               setLastName(customer[0].last_name);
               localStorage.setItem('email', customer[0].email);
               setEmail(customer[0].email);
            }
            setPhone(pno);
            nextStep();
         })
         .catch((err) => console.log(err));
   };

   useEffect(() => {
      let p = localStorage.getItem('phone');
      if (p !== null && p === phone) {
         if (customer!=null && customer.length>0) {
            localStorage.setItem('firstName', customer[0].first_name);
            setFirstName(customer[0].first_name);
            localStorage.setItem('lastName', customer[0].last_name);
            setLastName(customer[0].last_name);
            localStorage.setItem('email', customer[0].email);
            setEmail(customer[0].email);
         }
         nextStep();
      }
   }, []);

   return (
      <Grid
         container
         spacing={3}
         alignItems='center'
         justifyContent='center'
         direction='row'
      >
         <Paper
            elevation={4}
            style={{ padding: 20, width: 300, marginBottom: 60 }}
         >
            {!otpShow ? (
               <></>
            ) : (
               <IconButton
                  onClick={() => {
                     setOtpShow(false);
                     setOtp('');
                  }}
                  size='small'
               >
                  <ArrowBackIcon />
               </IconButton>
            )}
            {!otpShow ? (
               <h3>Enter your Phone Number</h3>
            ) : (
               <h3>Enter the OTP</h3>
            )}
            {otpShow ? (
               <p>
                  A One Time Password has been sent to your phone number for
                  verification purposes.
               </p>
            ) : null}
            <div>
               {!otpShow ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 'auto',
                        justifyContent: 'space-around',
                     }}
                  >
                     <div
                        style={{
                           alignItems: 'flex-end',
                           justifyContent: 'center',
                           display: 'flex',
                           marginRight: 10,
                           width: 60,
                        }}
                     >
                        <TextField
                           id='code'
                           label='Code'
                           color='secondary'
                           value={code}
                           onChange={(e) => {
                              setCode(e.target.value);
                           }}
                        />
                     </div>
                     <div>
                        <TextField
                           id='phone'
                           label='Phone'
                           color='secondary'
                           value={pno}
                           onChange={(e) => {
                              if (
                                 (e.target.value[e.target.value.length - 1] >=
                                    '0' &&
                                    e.target.value[e.target.value.length - 1] <=
                                       '9') ||
                                 !e.target.value
                              ) {
                                 setPno(e.target.value);
                              }
                           }}
                        />
                     </div>
                  </div>
               ) : (
                  <Otp otp={otp} setOtp={(val) => setOtp(val)} />
               )}
               {otpShow ? (
                  <div
                     style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: 5,
                     }}
                  >
                     Didn't receive an OTP?{' '}
                     <Button
                        onClick={() => getCode()}
                        color='primary'
                        style={{ textTransform: 'none', fontSize: 15 }}
                     >
                        Resend OTP
                     </Button>
                  </div>
               ) : null}
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     marginTop: 20,
                  }}
               >
                  <Button
                     variant='contained'
                     // disabled={
                     //    pno.length !== 10 ||
                     //    code === null ||
                     //    !isNumeric(pno) ||
                     //    (otpShow && otp.length !== 6)
                     // }
                     color='secondary'
                     style={{
                        color: 'white',
                        marginLeft: 'auto',
                        textTransform: 'none',
                     }}
                     onClick={() => {
                        if (otpShow) {
                           verifyCode();
                        } else {
                           getCode();
                           setOtpShow(true);
                        }
                     }}
                  >
                     Verify
                  </Button>
               </div>
            </div>
         </Paper>
      </Grid>
   );
};

export default Verify;
