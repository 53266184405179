import React from 'react';
import {
   Button,
   Checkbox,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   FormControlLabel,
   InputLabel,
   ListItemText,
   MenuItem,
   OutlinedInput,
   Radio,
   RadioGroup,
   Select,
} from '@mui/material';
import { useAvailableEmployees } from '../hooks/useAvailableEmployees';

const OptionDialog = (props) => {
   const {
      onClose,
      customers: valueProp,
      open,
      service_id,
      start_date,
      start_time,
      min_customer,
      ...other
   } = props;
   const {
      data: availableEmployees,
      isLoading: isLoading3,
      error: error3,
   } = useAvailableEmployees({
      service_id: service_id,
      start_date: start_date,
      start_time: start_time,
      store_id: process.env.REACT_APP_STORE_ID,
   });
   const [value, setValue] = React.useState(valueProp); //number of customers
   const radioGroupRef = React.useRef(null);

   let showEmployees = process.env.REACT_APP_SHOW_EMPLOYEES;
   let options = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

   options = options.slice(min_customer-1, availableEmployees?.length);

   const ITEM_HEIGHT = 48;
   const ITEM_PADDING_TOP = 8;
   const MenuProps = {
      PaperProps: {
         style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
         },
      },
   };

   const names = availableEmployees?.map((employee) => employee.first_name);
   const [personName, setPersonName] = React.useState([]);

   const handleNameChange = (event) => {
      const {
         target: { value },
      } = event;
      setPersonName(
         // On autofill we get a stringified value.
         typeof value === 'string' ? value.split(',') : value
      );
   };

   React.useEffect(() => {
      if (!open) {
         setValue(valueProp);
      }
   }, [valueProp, open]);

   const handleCancel = () => {
      onClose();
   };

   const handleOk = () => {
      let employeeIds = availableEmployees.filter((employee) => personName.includes(employee.first_name)).map((employee) => employee.employee_id);

      let selectedStatus = Array(Number(value)).fill(0)
      for(let i = 0; i < employeeIds.length; i++){
         selectedStatus[i] = 1
     }
      if (employeeIds.length >= parseInt(value)) {
         employeeIds = employeeIds.sort(() => Math.random() - 0.5).slice(0, value);
      }else{
         let result = availableEmployees.filter(employee => ! employeeIds.includes(employee.employee_id)).map((employee) => employee.employee_id)
         result = result.sort(() => Math.random() - 0.5).slice(0, value-employeeIds.length);
         employeeIds = employeeIds.concat(result)
      }

      onClose(value,employeeIds,selectedStatus);
   };

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   return (
      <Dialog
         maxWidth='xs'
         aria-labelledby='confirmation-dialog-players'
         open={open}
         {...other}
      >
         <DialogTitle id='confirmation-dialog-title'>
            How many people are you booking for?
         </DialogTitle>
         <DialogContent dividers>
            <RadioGroup
               ref={radioGroupRef}
               aria-label='number of customers'
               name='numOfCustomers'
               value={value}
               onChange={handleChange}
            >
               {options.map((option) => (
                  <FormControlLabel
                     value={option}
                     key={option}
                     control={<Radio />}
                     label={option}
                  />
               ))}
            </RadioGroup>
            {showEmployees === 'true' && (
            <FormControl sx={{ m: 1, width: 300 }}>
               <InputLabel id='demo-multiple-checkbox-label'>Select therapist (option)</InputLabel>
               <Select
                  labelId='select-employee-label'
                  id='select-employee-id'
                  multiple
                  value={personName}
                  onChange={handleNameChange}
                  input={<OutlinedInput label='Tag' />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
               >
                  {names?.map((name) => (
                     <MenuItem key={name} value={name}>
                        <Checkbox checked={personName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
            )}
         </DialogContent>
         <DialogActions>
            <Button autoFocus onClick={handleCancel} color='primary'>
               Cancel
            </Button>
            <Button onClick={handleOk} color='primary'>
               Ok
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default OptionDialog;
