import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchServicesByCategory = async (store_id,category_id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/service/category?store_id=${store_id}&category_id=${category_id}`
   );
   return data;
};

export function useServicesByCategory(store_id,category_id) {
   return useQuery(['services', store_id,category_id], () => fetchServicesByCategory(store_id,category_id));
}
