import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchCustomer = async (store_id,phone_number) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/customer/phone?phone=${phone_number}&store_id=${store_id}`
   );
   return data;
};

export function useGetCustomerInfo(store_id,phone_number) {
   return useQuery(['customer', phone_number,store_id], () => fetchCustomer(store_id,phone_number));
}
