import { Button, Grid, Slide } from '@mui/material';
import React from 'react';
import moment from 'moment-timezone';
import { useAvailableEmployees } from '../hooks/useAvailableEmployees';
const ScheduleItem = ({
   schedule_time,
   price,
   id,
   selectedID,
   onSelect,
   onConfirm,
   schedule,
}) => {
   const {isLoading, data } = useAvailableEmployees({start_date: schedule.schedule_date, start_time:schedule.schedule_time, service_id: schedule.service_id, store_id: schedule.store_id})
   // if (isLoading) return <div>Loading...</div>;

   let isShown = data?.length >= schedule.num_of_customer;
   if (!isShown) {
      return (<></>)
   }
   return (
     
      <>
         <Grid item xs={selectedID===id?6:12}>
            <Button
               disabled={selectedID === id}
               fullWidth
               variant='outlined'
               color={'primary'}
               id={id}
               key={id}
               style={{
                  backgroundColor: selectedID === id ? 'grey' : 'white',
                  borderWidth: selectedID === id ? 0 : 1,
                  color: selectedID === id ? 'white' : 'black',
               }}
               onClick={() => {
                 onSelect(id);
               }}
            >
                {moment(schedule_time, 'HH:mm:ss').format('hh:mm A')} - ${Number(price).toFixed(2)}
            </Button>
         </Grid>
         {selectedID === id && (
             <Slide direction={'left'} in={selectedID === id}>
             <Grid item xs={6}>
                
                   <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color={'primary'}
                      id={id + "confirm"}
                      key={id + "confirm"}
                      style={{
                        display:
                           selectedID === id ? "block" : "none",
                     }}
                      disableElevation
                        onClick={() => {onConfirm(id);}}
                   >
                      Confirm
                   </Button>
                
             </Grid>
             </Slide>
            )}
      
      </>
   );
};

export default ScheduleItem;
