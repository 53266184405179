import {
   Avatar,
   Box,
   Button,
   Checkbox,
   Container,
   CssBaseline,
   FormControlLabel,
   Grid,
   TextField,
   Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useStateContext } from './Contexts/ContextProvider';
import { useSaveFeedback } from './hooks/useSaveFeedback';
import { useSendCoupon } from './hooks/useSendCoupon';
import { useFeedback } from './hooks/useFeedback';

function Copyright() {
   let site_name = process.env.REACT_APP_SITE;
   return (
      <>
         <Typography variant='body2' color='text.secondary' align='center'>
            {process.env.REACT_APP_COMPANY_INFO}
         </Typography>
         <Typography variant='body2' color='text.secondary' align='center'>
            {'Copyright © '}
            <Link color='inherit' href={'https://' + site_name}>
               {site_name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
         </Typography>
      </>
   );
}

const Feedback = () => {
   let { id } = useParams();
   let navigate = useNavigate();
   const [eventId, setEventId] = React.useState(null);
   const [score, setScore] = React.useState(0);
   const [customerPhone, setCustomerPhone] = React.useState('');
   const [fb, setFb] = React.useState('');
   const { setFeedback } = useStateContext();
   const { saveFeedback } = useSaveFeedback();
   const { isLoading, data } = useFeedback(eventId);
   const [error, setError] = React.useState(null);
   const [enabled, setEnabled] = React.useState(true);
   const { sendCoupon } = useSendCoupon();
   useEffect(() => {
      try {
         let str = atob(id);
         let eventId = str.split('|')[0];
         let score = str.split('|')[1];
         let customerPhone = str.split('|')[2];
         if (isNaN(eventId)) {
            navigate('/404');
         } else {
            setEventId(eventId);
            setScore(score);
            setCustomerPhone(customerPhone);
         }
      } catch (e) {
         navigate('/404');
      }
   }, []);
   if (isLoading) {
      return <div>Loading...</div>;
   }
   const handleSubmit = () => {
      setFeedback(fb);

      saveFeedback(
         {
            event_id: eventId,
            score: score,
            content: fb,
            customer_phone: customerPhone,
         },
         {
            onSuccess: () => {
               //check if review discount true
               if (process.env.REACT_APP_REVIEW_DISCOUNT) {
                  sendCoupon({
                     customer_phone: customerPhone,
                     store_id: process.env.REACT_APP_STORE_ID,
                     discount: process.env.REACT_APP_REVIEW_DISCOUNT_AMOUNT,
                  });
               }
               setError('Thank you for your feedback!');
               setEnabled(false);
            },
            onError: (error) => {
               console.log('error', error);
               setError(error.response.data);
            },
         }
      );
   };

   return (
      <Container component='main' maxWidth='xs'>
         <CssBaseline />
         <Box
            sx={{
               marginTop: 8,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <img src='/logo.png' alt='logo' width={'60px'} height={'60px'} />
            <Typography component='h1' variant='h5'>
               {process.env.REACT_APP_COMPANY_NAME}
            </Typography>
            {data.length === 0 && (
               <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Typography component='h1' variant='body2'>
                           Tell us a bit more about why you chose {score}?
                        </Typography>
                     </Grid>
                     <Grid item xs={12}>
                        <TextareaAutosize
                           aria-label='minimum height'
                           minRows={3}
                           placeholder='Please enter your feedback here'
                           style={{ width: '100%' }}
                           value={fb}
                           onChange={(e) => setFb(e.target.value)}
                        />
                     </Grid>
                  </Grid>
                  {error && (
                     <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <Typography component='h1' variant='body2'>
                                 {error}
                              </Typography>
                           </Grid>
                        </Grid>
                     </Box>
                  )}
                  <Button
                     type=''
                     fullWidth
                     variant='contained'
                     sx={{ mt: 3, mb: 2 }}
                     onClick={handleSubmit}
                     disabled={!enabled}
                  >
                     Submit
                  </Button>
               </Box>
            )}
            {data.length > 0 && (
               <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Typography component='h1' variant='body2'>
                           Thank you for your feedback!
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            )}
         </Box>
         <Copyright sx={{ mt: 10 }} />
      </Container>
   );
};

export default Feedback;
