import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const saveFeedback = async (values) => {
   const data = await axios.post(
      process.env.REACT_APP_API_SERVER + `/api/feedback`,
      values
   );
   return data;
};

export function useSaveFeedback() {
   const { isLoading, mutate } = useMutation(saveFeedback);

   return { isLoading: isLoading, saveFeedback: mutate };
}
