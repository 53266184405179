import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import { Box, Button, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Search from './Search';
import Review from './Review';
import Verify from './Verify';
import Finish from './Finish';
import Contact from './Contact';

function Copyright() {
   let site_name = process.env.REACT_APP_SITE;
   return (
      <>
      <Typography variant='body2' color='text.secondary' align='center'>
         {process.env.REACT_APP_COMPANY_INFO}
         </Typography>
      <Typography variant='body2' color='text.secondary' align='center'>
         {'Copyright © '}
         <Link color='inherit' href={'https://' + site_name}>
            {site_name}
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
      </>

   );
}

const steps = ['Search', 'Verify', 'Contact', 'Review'];

const BookAppointment = () => {
   const [activeStep, setActiveStep] = React.useState(0);
   const handleNext = () => {
      setActiveStep(activeStep + 1);
   };

   const handleBack = () => {
      setActiveStep(activeStep - 1);
   };
   const firstStep = () => {
      setActiveStep(0);
   };
   function getStepContent(step) {
      switch (step) {
         case 0:
            return <Search nextStep={handleNext} />;
         case 1:
            return <Verify prevStep={handleBack} nextStep={handleNext} />;

         case 2:
            return <Contact prevStep={handleBack} nextStep={handleNext} firstStep={firstStep}/>;
         case 3:
            return (
               <Review
                  prevStep={handleBack}
                  nextStep={handleNext}
                  firstStep={firstStep}
               />
            );
         case 4:
            return <Finish firstStep={firstStep} />;
         default:
            throw new Error('Unknown step');
      }
   }
   
   return (
      <div>
         <AppBar
            position='absolute'
            color='default'
            elevation={0}
            sx={{
               position: 'relative',
               borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
         >
            <Toolbar>
               <div>
                  <img
                     src='logo.png'
                     alt='logo'
                     width={'180px'}
                     height={'60px'}
                  />
               </div>
               <Typography variant='h6' color='inherit' noWrap>
                  {process.env.REACT_APP_COMPANY_NAME}
               </Typography>
            </Toolbar>
         </AppBar>
         <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
            <Paper
               variant='outlined'
               sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
               <Typography component='h1' variant='h4' align='center'>
                  Book Appointment
               </Typography>
               <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                  {steps.map((label) => (
                     <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                     </Step>
                  ))}
               </Stepper>
               <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            </Paper>
            <Copyright />
         </Container>
      </div>
   );
};

export default BookAppointment;
