import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const fetchFeedback = async (event_id) => {
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/feedback/event?event_id=${event_id}`
   );
   return data;
};

export function useFeedback(event_id) {
   return useQuery(['feedback', event_id], () => fetchFeedback(event_id));
}
