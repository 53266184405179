import { Button, Grid, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import React from 'react'

const Finish = ({firstStep}) => {
   const queryClient = useQueryClient()
  let confirmation = Math.round(Math.random() * 10000000).toString()

  function handleBook() {
   queryClient.invalidateQueries('schedules')
    firstStep()
  }
  return (
    <React.Fragment>
         <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            direction="column"
         >
            <Grid item xs={6}>
               <Typography variant="h6" align="center">
                  Thank you for booking your appointment!
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant="subtitle1" align="center">
                  Your booking details are below.
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant="subtitle1" align="center">
                  Confirmation #{confirmation}. We have text your
                  reservation confirmation.
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Button variant="contained" color="primary" onClick={handleBook}>
                  Book Another Appointment
               </Button>
            </Grid>
         </Grid>
         
      </React.Fragment>
  )
}

export default Finish